<template>
  <div class="relative whitespace-normal">
    <div @mouseenter="mouseEnter" @mouseleave="mouseLeave">
      <InertiaLink
        href="/alerts"
        class="flex text-xs"
        :class="url.startsWith('/alerts') ? 'text-white' : 'text-gray-300 hover:text-white'"
        @click="$emit('click')"
      >
        <div
          :class="`${
            url.startsWith('/alerts') ? 'rounded-full bg-gradient-to-t from-blue-500 to-blue-600' : ''
          } mr-1.5 h-4 w-0.5`"
        ></div>
        <span class="inline-flex whitespace-nowrap pr-1">
          <slot name="label" />
          <div class="ml-1" v-if="unreadNotifications > 0 || unreadNotifications == '99+'">
            <span class="rounded bg-blue-900 px-1 py-0.5 text-blue-100" style="font-size: 0.6rem">
              {{ unreadNotifications }}
            </span>
          </div>
        </span>
        <ChevronDownIcon
          :class="{ 'rotate-180': showDropdown }"
          class="-ml-0.5 h-4 w-4 transform text-gray-400 duration-100 ease-in-out"
        />
      </InertiaLink>

      <div class="absolute top-6 z-51 w-96" v-if="showDropdown">
        <div class="flex flex-col rounded border border-grid bg-gray-800 px-1 py-2">
          <!-- <template v-if="loading">
            <div class="flex justify-center items-center my-10">
              <BaseLoader />
            </div>
          </template>
          <template v-else> -->
          <div v-if="notifications.length > 0" class="flex space-x-2 px-1">
            <BaseButton size="xs" type="primary" :busy="buttonBusy" :disabled="buttonBusy" @press="markAllRead()">
              Mark All Read
            </BaseButton>
            <BaseButton size="xs" type="primary" @press="deleteAll()"> Delete All</BaseButton>
          </div>
          <div class="thin-scroll mt-2 max-h-96 space-y-1 overflow-y-auto">
            <template v-if="notifications.length > 0">
              <NavigationNotificationItem
                v-for="n in notifications"
                :key="n.id"
                :notification="n"
                @mark-as-read="markAsRead = $event"
              />
            </template>
            <template v-else>
              <div class="flex flex-col items-center justify-center px-4 py-8">
                <span class="mb-2 text-sm font-medium">No recent notifications.</span>
                <span class="text-center text-xs text-gray-400">Perhaps you didn't set any Alert yet.</span>
              </div>
            </template>
          </div>
          <!-- </template> -->
          <div v-if="notifications.length > 0" class="mt-2 flex justify-center text-center font-semibold">
            <InertiaLink href="/alerts/active" class="text-xs text-white">See All</InertiaLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import useHttp from '@/composeables/http';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { Link as InertiaLink, usePage } from '@inertiajs/vue3';
import NavigationNotificationItem from '@/components/navigation/NavigationNotificationItem.vue';

const $store = useStore();
const $http = useHttp();
const url = computed(() => usePage().url);

const showDropdown = ref(false);
const intervalDropdown = ref(0);
const markAsRead = ref(true);
const buttonBusy = ref(false);

const unreadNotifications = computed(() => {
  let count = 0;
  count = notifications.value.filter(x => !x.read).length;
  if (count >= 100) count = '99+';
  return count;
});

const notifications = computed(() => {
  return $store.state.userNotifications;
});

function mouseEnter() {
  clearInterval(intervalDropdown.value);
  intervalDropdown.value = setTimeout(() => {
    showDropdown.value = true;
  }, 500);
}

function mouseLeave() {
  clearInterval(intervalDropdown.value);
  if (showDropdown.value) {
    intervalDropdown.value = setTimeout(() => {
      markAllRead(false);
      showDropdown.value = false;
    }, 500);
  }
}

async function markAllRead(message = true) {
  let read_all_alerts = null;
  // eslint-disable-next-line chai-friendly/no-unused-expressions
  message ? (read_all_alerts = message) : (read_all_alerts = markAsRead.value);
  if (showDropdown.value && (unreadNotifications.value > 0 || unreadNotifications.value == '99+') && read_all_alerts) {
    buttonBusy.value = true;
    await $http.post('/notifications/mark_read', { id: 'all', message: message }).then(() => {
      $store.commit('changeUserNotificationStatus', { id: 0, status: false });
    });
    buttonBusy.value = false;
  }
}

function deleteAll() {
  $http.post('/notifications/delete', { id: 'all' }).then(() => {
    $store.commit('setUserNotifications', []);
  });
}
</script>
